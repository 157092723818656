import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { clone, unionWith } from 'lodash';
import DataTable from 'react-data-table-component';
import { useReactToPrint } from 'react-to-print';
import { useSnackbar } from 'notistack';

import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CachedRoundedIcon from '@mui/icons-material/CachedRounded';

import EventBus from '@/config/event-handler';
import { useTranslation } from '@/hooks/translations';
import { restrictRoles } from '@/constants/roleRestrictions';
import { useUserContext } from '@/contexts/user';
import { vendorPaymentEvent, resetNotificationBadgeCount } from '@/contexts/event.const';
import { useRestaurantContext } from '@/contexts/restaurant';
import { covertUtcToRestaurantDateTime } from '@/common/utility';
import { PosExtraInfo, ToppingView } from '@/pages/api/type';
import { IVPosResponse } from '@/views/VPOS/order-card';
import { IPaymentTransaction } from '@/views/Orders/types';
import { IQsrOrder } from '@/views/QsrOrders/types';

import OrdersService from '@/services/order';
import { getCurrencyPrecision } from '@/services/utils/k_currency';
import RefundService from '@/services/refundService';
import RestaurantService from '@/services/restaurant';
import { isTableTransactionMatch } from '@/services/utils/table';
import { onPushEvent } from '@/services/lib/gtm';

import { CustomButton, Section } from '@/components/common';
import { OrdersTablePagination } from '@/components/Table/pagination';
import RefundModal from '@/components/RefundModal';
import RefundReceipt from '@/components/RefundReceipt';
import ReceiptV2 from '@/components/ReceiptV2';
import { formatISOTimezone } from '@/components/TimeRange/Form/utils';
import TimeDrawer from '@/components/TimeRange/Drawer';
import { TimeRangeTypes } from '@/components/TimeRange/Form/types';

import { IRestaurantTables, ITableParams } from '../OrdersTableView/types';
import { useFilters } from './useFilters';
import { useColumns } from './useColumns';
import ReportManagementCreateModal from '../Reports/export';
import OrderExportModal, { ReportTypes } from '../Orders/export';

export interface IPayment {
    transactionId: string;
    tableId: string;
    tableName: string;
    table_data: ITableData;
    time: string;
    date: string;
    orderId: string;
    billAmount: string;
    paidAmount: string | null;
    netTipAmount: string | null;
    grossTipAmount: string | null;
    receiptTipAmount: string | null;
    customerCommission: string | null;
    totalAmount: string | null;
    refundedAmount: number;
    cardBrand: string | null;
    paid: boolean;
    timestamp: number;
    currencySymbol: string;
    currencyCode: string;
    reference: string | null;
    countryCode: string;
    gatewayVendor: string | null;
    restaurantUnique: string;
    paymentRecordId: string | null;
    type: PaymentRecordType | null;
    madaCard: string | null;
    paidStatus: string | null;
    voucher: string | null;
    loyalty: string | null;
    last4: string | null;
    forexValue: string | null;
    customerPaidExcludingDinerFee: number | null;
    status: string;
}

export interface IRefundData extends IPayment {
    refundableAmount?: number;
}

interface IOrderAdditive {
    association: string;
    category: string;
    title: string;
    titleTrans: Record<string, string>;
    value: string;
    valueType: string;
}

interface IOrderItem {
    qty: string;
    title: string;
    unitPrice: string;
    basePrice?: string;
    finalPrice?: string;
    updatedAt: number;
    toppings?: Array<ToppingView> | null;
    additives?: Array<IOrderAdditive> | null;
}

interface IOrderData {
    id: string;
    tax?: string;
    vat: string;
    items?: IOrderItem[];
    amount: string;
    subTotal?: string;
    table_id: string;
    currency?: string;
    additives?: Array<IOrderAdditive> | null;
}

export enum PaymentRecordType {
    User = 'qlub-user',
    NonQlubUser = 'non-qlub-user',
    Discount = 'qlub-discount',
    Refund = 'qlub-refund',
    Incentive = 'qlub-incentive',
    Terminal = 'qlub-terminal',
    Voucher = 'qlub-voucher',
    Loyalty = 'qlub-loyalty',
    Rejected = 'rejected',
}

export interface ICommission {
    id: string;
    category: string;
    amount: string;
}

export interface IExtraInfo {
    commissions: ICommission[];
    customer: ICustomer;
    phone_number: string;
}

export interface ICustomer {
    email: string;
    phone: string;
    name: string;
}

export interface ICustomerData {
    uuid: string;
    phone: string;
    email: string;
    name: string;
    birthDate: string;
    emailVerified: boolean;
    phoneVerified: boolean;
    isGuest: boolean;
    registeredAt: number;
    data: [
        {
            id: number;
            uuid: string;
            category: string;
            vendor: string;
            '': {
                sed_4: string;
            };
        },
        {
            id: number;
            uuid: string;
            category: string;
            vendor: string;
            '': {
                dolor_37c: string;
                in_f3: string;
                amet_8: string;
                euf8: string;
            };
        },
    ];
}

export interface IPaymentRecord {
    tip_commission: number;
    id: string;
    table_session_id: string;
    party_id: string;
    table_id: string;
    party_name: string;
    paid_amount: string;
    tip_amount: string;
    reference: string;
    gateway_vendor: string;
    status: string;
    commission_amount: string;
    commission_id: string;
    created_at: string;
    updated_at: string;
    pg_extra_info: any;
    vendor_commission: number;
    customer_commission: number;
    type: PaymentRecordType;
    paidStatus: string;
    extra_info?: IExtraInfo;
    customer_paid_excluding_diner_fee: number;
    txn_id: string;
    customerInfo: ICustomerData;
}

export interface IRefundPaymentRecord extends IPaymentRecord {
    refund_amount: string;
}

export interface ITableData {
    id: string;
    name: string;
    extra: string;
    floor: string;
    section: string;
    revenueCenter: string;
    active_order_id?: string;
    activeOrderId?: string;
}

export interface ITransaction {
    id: string;
    country_code: string;
    restaurant_unique: string;
    ops_mode: string;
    table_id: string;
    order_id: string;
    paid: boolean;
    closed: boolean;
    bill_amount: string;
    order_data: IOrderData;
    created_at: string;
    updated_at: string;
    paymentRecord?: IPaymentRecord[];
    table_data: ITableData;
    pos_extra_info?: PosExtraInfo | null;
    vpos?: IVPosResponse;
}

export interface IPaymentNotificationMessage {
    eventName: string;
    details: IPaymentTransaction;
}

export interface IPaymentNotificationEvent {
    detail: IPaymentTransaction[];
}

export enum NotifyVendorEnum {
    NewOrder = 'newOrder',
    PaidOrder = 'paidOrder',
    UpdateStatus = 'updateStatus',
}

export interface IOrderNotificationMessage {
    eventName: NotifyVendorEnum;
    details: IQsrOrder;
}

export interface IOrderNotificationEvent {
    detail: IQsrOrder[];
}

export interface IRefundConfigItem {
    pgId: string;
    name: string;
    refundType: string;
}

export interface IRefundConfig {
    refundConfigs: IRefundConfigItem[];
}

const convertTransactionToPayment = (
    list: ITransaction[],
    currencySymbol: string,
    currencyCode: string,
    qrList?: ITableParams[],
): IPayment[] => {
    const out: IPayment[] = [];
    const precision = getCurrencyPrecision(currencyCode);
    list.forEach((item) => {
        const preferedName = qrList?.find((qr) => isTableTransactionMatch(qr, item))?.name || '';
        if (item.paymentRecord?.length) {
            const refundedAmount =
                item.paymentRecord?.reduce((a, b) => {
                    if (b.type === PaymentRecordType.Refund) {
                        return a + Number(b.paid_amount);
                    }
                    return a;
                }, 0) || 0;
            item.paymentRecord?.forEach((paymentRecord) => {
                const tipCommission = (paymentRecord.extra_info?.commissions || [])
                    .filter((c) => c.category === 'tip')
                    .reduce((sum, c) => sum + Number(c.amount || 0), 0);
                out.push({
                    transactionId: item.id,
                    tableId: item.table_id,
                    tableName: preferedName || item.table_data.name,
                    table_data: item.table_data,
                    orderId: item.order_data.id,
                    billAmount: item.bill_amount,
                    paidAmount: (Number(paymentRecord.paid_amount) - paymentRecord.customer_commission).toFixed(
                        precision,
                    ),
                    netTipAmount: `${Number(paymentRecord.tip_amount) - tipCommission}`,
                    grossTipAmount: paymentRecord.tip_amount,
                    receiptTipAmount: paymentRecord.tip_amount,
                    customerCommission: paymentRecord.customer_commission?.toFixed(precision),
                    totalAmount: (Number(paymentRecord.paid_amount) + Number(paymentRecord.tip_amount)).toFixed(
                        precision,
                    ),
                    refundedAmount: Number(refundedAmount.toFixed(precision)),
                    cardBrand: paymentRecord.pg_extra_info ? paymentRecord.pg_extra_info.cardBrand : null,
                    currencySymbol: currencySymbol.toUpperCase(),
                    currencyCode,
                    paid: item.paid,
                    date: covertUtcToRestaurantDateTime(paymentRecord.updated_at, 'MM/DD/YYYY'),
                    time: covertUtcToRestaurantDateTime(paymentRecord.updated_at, 'HH:mm'),
                    timestamp: dayjs(paymentRecord.updated_at).unix(),
                    reference: paymentRecord.reference,
                    countryCode: item.country_code,
                    gatewayVendor: paymentRecord.gateway_vendor,
                    restaurantUnique: item.restaurant_unique,
                    paymentRecordId: paymentRecord.id,
                    type: paymentRecord.type || PaymentRecordType.User,
                    madaCard: paymentRecord.pg_extra_info?.madaCard ?? null,
                    paidStatus: paymentRecord.paidStatus,
                    voucher: paymentRecord.type === PaymentRecordType.Voucher ? paymentRecord.paid_amount : null,
                    loyalty: paymentRecord.type === PaymentRecordType.Loyalty ? paymentRecord.paid_amount : null,
                    last4: paymentRecord.pg_extra_info?.last4 ?? null,
                    forexValue: paymentRecord.pg_extra_info?.exchangeDetails
                        ? `${paymentRecord.pg_extra_info?.exchangeDetails?.Currency} ${paymentRecord.pg_extra_info?.exchangeDetails?.Amount}`
                        : null,
                    customerPaidExcludingDinerFee: paymentRecord?.customer_paid_excluding_diner_fee,
                    status: paymentRecord.status,
                });
            });
        } else {
            out.push({
                transactionId: item.id,
                tableId: item.table_id,
                tableName: item.order_data.table_id,
                table_data: item.table_data,
                orderId: item.order_data.id,
                billAmount: item.bill_amount,
                paidAmount: null,
                netTipAmount: null,
                grossTipAmount: null,
                receiptTipAmount: null,
                customerCommission: null,
                totalAmount: null,
                refundedAmount: 0,
                cardBrand: null,
                currencySymbol: currencySymbol.toUpperCase(),
                currencyCode,
                paid: item.paid,
                date: covertUtcToRestaurantDateTime(item.updated_at, 'MM/DD/YYYY'),
                time: covertUtcToRestaurantDateTime(item.updated_at, 'HH:mm'),
                timestamp: dayjs(item.updated_at).unix(),
                reference: null,
                countryCode: item.country_code,
                gatewayVendor: null,
                restaurantUnique: item.restaurant_unique,
                paymentRecordId: null,
                type: null,
                madaCard: null,
                paidStatus: null,
                voucher: null,
                loyalty: null,
                last4: null,
                forexValue: null,
                customerPaidExcludingDinerFee: null,
                status: '',
            });
        }
    });
    return out.sort((a, b) => {
        return a.timestamp > b.timestamp ? -1 : 1;
    });
};

export function parseTransactionNotification(li: ITransaction[], msgList: IPaymentTransaction[], vpos?: boolean) {
    li = clone(li);
    msgList.forEach((msg) => {
        const idx = li.findIndex((o) => (vpos ? o.vpos?.orderId === msg.vpos?.orderId : o.id === msg.id));
        if (idx > -1) {
            const idx2 = li[idx].paymentRecord?.findIndex((o) => o.id === msg?.paymentRecord?.[0].id) || -1;
            if (idx2 === -1) {
                li[idx] = {
                    ...msg,
                    paymentRecord: [...(msg.paymentRecord || []), ...(li[idx].paymentRecord || [])],
                };
            }
        } else {
            li = [msg, ...li];
        }
    });
    return li;
}

export default function notifications() {
    const restaurantService = RestaurantService.getInstance();
    const ordersService = OrdersService.getInstance();
    const refundService = RefundService.getInstance();
    const { t } = useTranslation('common');
    const { user } = useUserContext();
    const { restaurant } = useRestaurantContext();
    const [filteredData, setFilteredData] = useState<IPayment[]>([]);
    const [printDoc, setPrintDoc] = useState<IPayment | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState<number>(0);
    const [qrList, setQrList] = useState<ITableParams[]>();

    const [refreshBoolean, setRefreshBoolean] = useState<boolean>(false);
    const [refundModalIsOpen, setRefundModalIsOpen] = useState<boolean>(false);
    const [refundData, setRefundData] = useState<IRefundData | null>(null);
    const [refundConfig, setRefundConfig] = useState<IRefundConfig | null>(null);
    const [refundConfigMap, setRefundConfigMap] = useState<{ [key: string]: IRefundConfigItem }>({});
    const [currency, setCurrency] = useState<{ code: string; symbol: string }>({
        code: '',
        symbol: '',
    });

    const showTip = !restaurant?.config?.disableTipForVendorUsers || false;

    const { enqueueSnackbar } = useSnackbar();

    const theme = useTheme();
    const tablet = useMediaQuery(theme.breakpoints.down('lg'));
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    const printRef = useRef<any>(undefined);
    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });

    const refundHandler = (paymentData: IPayment) => {
        const refundsForOrderId = filteredData.filter((payment: IPayment) => {
            return (
                payment.orderId === paymentData.orderId &&
                payment.type === PaymentRecordType.Refund &&
                payment.reference?.includes(paymentData.reference || '')
            );
        });

        const refundableAmount =
            Number(paymentData.totalAmount) -
            Number(
                refundsForOrderId.reduce((initialTotal: number, payment: IPayment) => {
                    return initialTotal + Number(payment.totalAmount);
                }, 0),
            );

        if (refundsForOrderId.length > 0) {
            refundsForOrderId.forEach((refund: IPayment) => {
                if (paymentData.reference && refund.reference?.includes(paymentData.reference)) {
                    setRefundData({
                        ...paymentData,
                        refundableAmount: refundableAmount || 0,
                    });
                } else {
                    setRefundData(paymentData);
                }
            });
        } else {
            setRefundData({
                ...paymentData,
                refundableAmount: refundableAmount || 0,
            });
        }
        setRefundModalIsOpen(true);
        onPushEvent('user_click_refund_icon');
    };

    const refundModalCloseHandler = () => {
        setRefundModalIsOpen(false);
        setRefundData(null);
    };

    const columns = useColumns({
        refundConfigMap,
        refundHandler,
        filteredData,
        setPrintDoc,
    }).filter((c) => {
        if (!showTip) {
            return c.key !== 'tipAmount';
        }
        return true;
    });
    const { renderFilters, searchOrder, selectedStatus, timeRange, setTimeRange } = useFilters();

    const tablePageChangeHandler = (p: number) => {
        setPage(p);
    };

    const refresh = () => {
        setRefreshBoolean((o) => !o);
    };

    const getNotificationData = () => {
        if (!user.restaurantId || user.restaurantId === '') {
            return;
        }

        ordersService
            .orderNotification(user.restaurantId, {
                page: Number(page) - 1,
                search: searchOrder,
                status: selectedStatus === 'All' ? '' : selectedStatus,
                startDate: timeRange?.from ? formatISOTimezone(timeRange?.from) : null,
                endDate: timeRange?.to ? formatISOTimezone(timeRange?.to) : null,
                type: timeRange?.type,
            })
            .then((res) => {
                if (res.transactions) {
                    setCurrency({
                        symbol: res.currency_symbol,
                        code: res.currency_code,
                    });

                    const data = convertTransactionToPayment(
                        res.transactions.rows,
                        res.currency_symbol,
                        res.currency_code,
                        qrList,
                    );
                    setTotal(res.transactions.count);
                    setFilteredData(data);
                    if (page === 0) {
                        EventBus.dispatch(resetNotificationBadgeCount);
                    }
                }
            })
            .catch(() => {
                enqueueSnackbar(t('something went wrong'), { variant: 'error' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (qrList === undefined) return;
        setLoading(true);
        getNotificationData();
    }, [refreshBoolean, user, timeRange, page, searchOrder, selectedStatus, qrList]);

    useEffect(() => {
        if (!user || (user.userData.role || '').indexOf('Vendor') === -1) {
            return () => {
                //
            };
        }

        onPushEvent('user_visit_notification_section');

        const notificationHandler = ({ detail: msgList }: IPaymentNotificationEvent) => {
            const data = convertTransactionToPayment(msgList, currency.symbol, currency.code);
            setTotal((o) => o + 1);
            setFilteredData((o) => {
                return [...data, ...o];
            });
        };

        EventBus.on(vendorPaymentEvent, notificationHandler);
        return () => {
            EventBus.remove(vendorPaymentEvent, notificationHandler);
        };
    }, [user]);

    useEffect(() => {
        if (!restaurant?.id) {
            return;
        }

        restaurantService
            .getQrs(user.restaurantId)
            .then((res) => {
                setQrList(
                    unionWith(
                        res.rows.reverse().map((qr: IRestaurantTables) => qr.params),
                        (o1, o2) =>
                            o1.id === o2.id && (o1.f1 || '') === (o2.f1 || '') && (o1.f2 || '') === (o2.f2 || ''),
                    ),
                );
            })
            .catch(console.log);

        refundService.getRefundConfig(restaurant?.id || '').then((res) => {
            setRefundConfig(res);
            setRefundConfigMap(
                (res?.refundConfigs as IRefundConfigItem[])?.reduce<any>((a, b) => {
                    a[b.name] = b;
                    return a;
                }, {}) || {},
            );
        });
    }, [restaurant]);

    const getCustomRowClass = (o: IPayment) => {
        const now = Math.floor(Date.now() / 1000);
        const diffInMin = now - o.timestamp;

        if (diffInMin <= 600) {
            return '#FFECED';
        }
        return '';
    };

    const printHandler = (ref: any) => {
        if (!ref) {
            return;
        }

        ref.style.display = 'block';

        printRef.current = ref;
        handlePrint();
        setPrintDoc(null);
    };

    const expandableRowsComponent = (row: any) => {
        const omitTrueItems = columns.filter((c) => c.omit);

        return (
            <Grid container spacing={2} sx={{ marginTop: '10px' }}>
                {omitTrueItems.map((c, id) => {
                    if (c.key === 'pos_order_id' && !row.data.pos_extra_info?.pos_order_id) {
                        return null;
                    }

                    return (
                        <Grid item xs={12} key={id}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    padding: '0 10px',
                                }}
                            >
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    {c.name}
                                </Typography>
                                <Typography variant="body2" sx={{ color: 'text.primary' }}>
                                    {
                                        // @ts-ignore
                                        (c.selector && c.selector(row.data)) || (c.cell && c.cell(row.data))
                                    }
                                </Typography>
                            </Box>
                        </Grid>
                    );
                })}
            </Grid>
        );
    };

    return (
        <>
            <Grid
                container
                spacing={2}
                sx={{
                    marginBottom: '16px',
                    textAlign: 'right',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                }}
            >
                <Grid item xs={mobile ? 6 : undefined}>
                    {restaurant?.config?.releaseToggle_reportsManagement ? (
                        <ReportManagementCreateModal value={1} />
                    ) : (
                        restrictRoles.EXPORT_ORDERS_AND_TRANSACTIONS.includes(user?.userData?.role) && (
                            <OrderExportModal initialFileFormat={ReportTypes.TRANSACTION} />
                        )
                    )}
                </Grid>
                <Grid item xs={mobile ? 6 : undefined} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <CustomButton
                        onClick={() => {
                            refresh();
                            onPushEvent('user_click_on_refresh');
                        }}
                        endIcon={<CachedRoundedIcon />}
                        size="large"
                        sx={{ color: 'primary.main', width: '100%' }}
                    >
                        {t('Refresh')}
                    </CustomButton>
                </Grid>
                <Grid item xs={mobile ? 12 : undefined}>
                    <TimeDrawer
                        initialValue={{ type: TimeRangeTypes.LAST_30_DAYS, from: null, to: null }}
                        onConfirm={(val) => {
                            onPushEvent(`user_use_filter_date_${val.type}`);
                            setTimeRange(val);
                        }}
                        timeSelectEnabled
                    />
                </Grid>
            </Grid>
            <Section
                style={{
                    maxWidth: tablet ? '94vw' : 'unset',
                }}
            >
                <DataTable
                    customStyles={{
                        rows: {
                            style: {
                                borderBottom: 'none !important',
                            },
                        },
                        headRow: {
                            style: {
                                '& div': {
                                    whiteSpace: 'pre-wrap',
                                    justifyContent: 'flex-start',
                                },
                            },
                        },
                        headCells: {
                            style: {
                                justifyContent: 'flex-start',
                                ...(mobile
                                    ? {
                                          minWidth: 'unset !important',
                                      }
                                    : {
                                          '&:first-of-type': {
                                              minWidth: '60px !important',
                                          },
                                      }),
                            },
                        },
                        header: {
                            style: {
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'stretch',
                                paddingLeft: '0px',
                                fontSize: '1.5rem',
                                fontWeight: '400',
                                ...(mobile && { textAlign: 'center' }),
                            },
                        },
                        cells: {
                            style: mobile
                                ? {
                                      '&:first-of-type': {
                                          width: '50px',
                                      },
                                      minWidth: '50px !important',
                                  }
                                : {
                                      textAlign: 'start',
                                      flexDirection: 'column',
                                      alignItems: 'flex-start',
                                      '&:first-of-type': {
                                          minWidth: '60px !important',
                                      },
                                  },
                        },
                    }}
                    conditionalRowStyles={[
                        {
                            when: (row: any) => row,
                            style: (row) => ({
                                backgroundColor: getCustomRowClass(row),
                            }),
                        },
                    ]}
                    expandableRows={mobile}
                    expandableRowsComponent={(row) => {
                        return expandableRowsComponent(row);
                    }}
                    actions={renderFilters()}
                    fixedHeader
                    striped
                    columns={columns}
                    data={filteredData}
                    progressPending={loading}
                    pagination
                    paginationServer
                    paginationTotalRows={total}
                    onChangePage={tablePageChangeHandler}
                    paginationComponent={OrdersTablePagination}
                    sortServer
                    noDataComponent={
                        <Box
                            sx={{
                                width: '100%',
                                paddingRight: '1.2em',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    paddingRight: '1.2em',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    margin: '20px',
                                }}
                            >
                                {t('No order found with the applied filters!')}
                            </Box>
                            <OrdersTablePagination
                                onChangePage={tablePageChangeHandler}
                                currentPage={page}
                                isNextButtonDisabled={filteredData.length === 0}
                            />
                        </Box>
                    }
                />
            </Section>
            {restrictRoles.REFUND.includes(user.userData.role) && refundData && (
                <RefundModal
                    refundData={refundData}
                    open={refundModalIsOpen}
                    onClose={refundModalCloseHandler}
                    currencySymbol={currency.symbol}
                    currencyCode={currency.code}
                    refundConfig={refundConfig}
                    refresh={refresh}
                />
            )}
            {printDoc &&
                (printDoc.type === PaymentRecordType.Refund ? (
                    <RefundReceipt
                        payment={printDoc}
                        vendor={restaurant}
                        currencySymbol={currency.symbol}
                        currencyCode={currency.code}
                        innerRef={printHandler}
                    />
                ) : (
                    <ReceiptV2
                        payment={printDoc}
                        vendor={restaurant}
                        currencySymbol={currency.symbol}
                        currencyCode={currency.code}
                        innerRef={printHandler}
                    />
                ))}
        </>
    );
}
